@media (min-width: 1921px) {
  .home-villa-scrollbar,
  .planscroll-custm,
  .play-scrollbar {
    > div {
      overflow: hidden !important;
    }
  }
}
@media (max-width: 1850px) {
  .brandone {
    .atmosphere-discover {
      &::after {
        background-position: calc(100% - 50px) calc(50% + 10px), 54% bottom;
      }
    }
  }
  header {
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-item {
          position: relative;
          .nav-link {
            span {
              padding: 10px 15px;
              white-space: nowrap;
            }
          }
        }
        .booking-nav-item {
          margin-left: 2px;
          white-space: nowrap;
        }
      }
    }
  }
  .explore-kanifushi-plan {
    .plan-tab-feature-img {
      width: 780px;
    }
    .plan-tab-faqs {
      width: calc(100% - 780px);
    }
    .plan-questions {
      padding: 40px 30px;
    }
  }
  .our-plans-tabs {
    .tab-head-text {
      top: 80px;
    }
  }
  .plan-cover-image {
    height: 415px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
@media (max-width: 1670px) {
  header {
    padding-left: 15px;
    padding-right: 15px;
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-item {
          margin: 0 2px;
          .nav-link {
            span {
              padding: 10px 12px;
            }
          }
        }
        .logo-left-item {
          margin-right: 50px;
        }
        .logo-right-item {
          margin-left: 50px;
        }
        .logo-item {
          width: 190px;
          margin-left: 214px !important;
        }
        .nav-ul {
          width: calc(50% - 105px);
          &.end-nav-list {
            padding-left: 25px;
            .nav-item {
              &:first-child {
                margin-left: 50px;
              }
            }
          }
          &.start-nav-list {
            padding-right: 25px;
            .nav-item {
              &:last-child {
                margin-right: 50px;
              }
            }
          }
        }
      }
    }
    &.sticky {
      .atmosphere-living-nav {
        .navbar-nav {
          .logo-item {
            .logo-fixed {
              top: -35px;
            }
          }
        }
      }
    }
  }
  .brandone {
    .tab-text-view {
      &::after {
        background-position: left calc(100% - 40px), 30% 0;
      }
    }
    .atmosphere-discover {
      &::before {
        right: -80px;
      }
      &::after {
        background-position: calc(100% - 10px) calc(50% + 10px), 100% bottom;
      }
    }
  }
  .explore-kanifushi-plan {
    .rts___tabs___container {
      width: 370px;
      padding: 30px 20px;
    }
    .rts___tabs___container ~ div {
      width: calc(100% - 370px);
    }
    .plan-tab-feature-img {
      width: 780px;
    }
    .plan-tab-faqs {
      width: calc(100% - 780px);
    }
  }
  .explore-kanifushi-villas {
    .villa-details {
      width: 470px;
      padding: 45px 40px;
    }
    .villas-slides {
      width: calc(100% - 470px);
    }
  }
  .explore-villas-tab {
    .villas-slides {
      .large-slides {
        height: calc(100% - 110px);
      }
      .thumb-slides {
        .beach-slide-card {
          height: 75px;
        }
      }
    }
  }
}
@media (max-width: 1580px) {
  header {
    .header-wrap {
      &::before,
      &::after {
        width: calc(50% - 140px);
      }
    }
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-item {
          margin: 0;
          .nav-link {
            span {
              padding: 10px 11px;
            }
          }
          &:last-child {
            margin-left: 2px;
          }
        }
        .logo-item {
          width: 170px;
          margin-left: 230px !important;
        }
        .nav-ul {
          &.end-nav-list {
            .nav-item {
              &:first-child {
                margin-left: 0;
              }
            }
          }
          &.start-nav-list {
            .nav-item {
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    &.sticky {
      .atmosphere-living-nav {
        .navbar-nav {
          .logo-item {
            .logo-fixed {
              top: -32px;
            }
          }
        }
      }
    }
  }
  .brandone {
    .tab-text-view {
      &::after {
        background-position: left calc(100% - 40px), 21px 0;
        background-size: 51px, 47px;
      }
      &::before {
        left: -54px;
        background-size: 45px;
      }
    }
    .atmosphere-discover {
      &::before {
        right: -65px;
        background-size: 50px;
      }
      &::after {
        background-position: 0 calc(50% + 10px), 100% bottom;
        background-size: 100%;
        width: 72px;
        left: -72px;
      }
    }
  }
  footer {
    .mein-footer {
      .footer-links {
        a {
          &:not(:last-child) {
            span {
              padding-right: 24px;
            }
          }
        }
      }
    }
  }
  .explore-kanifushi-plan {
    .rts___tabs___container {
      width: 320px;
      .tab-feature-img {
        width: 65px;
      }
      .tab-name {
        width: calc(100% - 65px);
        text-align: left;
      }
    }
    .rts___tabs___container ~ div,
    .home-villa-scrollbar ~ div {
      width: calc(100% - 320px);
    }
    .plan-tab-feature-img {
      width: calc(100% - 375px);
    }
    .plan-tab-faqs {
      width: 375px;
    }
  }
  .explore-kanifushi-villas {
    .home-villa-scrollbar {
      max-width: 320px;
    }
    .rts___tabs___container {
      width: 320px;
      padding: 45px 20px;
    }
    .rts___tabs___container ~ div {
      width: calc(100% - 320px);
    }
    .villa-details {
      width: 400px;
      padding: 35px 25px;
    }
    .villas-slides {
      width: calc(100% - 400px);
    }
  }
  .planscroll-custm {
    height: 340px !important;
  }

  .atmosphere-book-now {
    .submit-col {
      max-width: fit-content;
      // margin-left: auto;
      // margin-right: auto;
      .submit-form {
        .btn {
          &.theme-btn {
            padding: 13.5px 25px;
          }
        }
      }
    }
  }
}
@media (max-width: 1450px) {
  .sub-card-para {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sub-card-title {
    font-size: 22px;
  }
  .sub-brands-tabs {
    padding-left: 25px;
    padding-right: 25px;
  }
  .slide-demo-box,
  .img-container {
    height: 590px !important;
  }
  .hotels-resorts-slides {
    .slide-caption {
      h1 {
        font-size: 52px;
      }
    }
  }
  .hotels-resorts {
    .slide-wrap {
      width: calc(100% - 650px);
    }
  }
  .bottom-right-images {
    max-width: 650px;
  }
  div#moveableDiv {
    .thumb-wrap {
      margin-left: 639px;
    }
  }
  .vertically-heading {
    left: -30px;
  }
  .section-padding {
    padding: 80px 0;
  }
  .our-plans-tabs {
    padding-left: 20px;
    padding-right: 20px;
  }
  .planscroll-custm {
    height: 310px !important;
  }
  .gallery-slide-modal {
    .modal-dialog {
      max-width: 1000px;
    }
  }
}
@media (max-width: 1399px) {
  .value-card-text {
    .value-card-title {
      margin: 35px 0 115px;
      padding: 0 15px;
    }
  }
  .right-feature-text-col {
    .right-feature-wrap {
      padding: 60px 60px 40px;
    }
    .feature-text-para {
      margin: 45px 0 0;
      &::after {
        margin: 45px auto 0;
      }
    }
  }
  .sub-card {
    bottom: -255px;
  }
  footer {
    .mein-footer {
      .footer-links {
        a {
          &:not(:last-child) {
            span {
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
  .discover-left,
  .discover-right {
    .discover-card-body {
      padding: 20px 40px;
    }
    .discover-card-image {
      min-height: 292.83px;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .sub-brands-tabs {
    .tab-feature-img {
      height: 40px;
      width: 40px;
    }
    .tab-name {
      width: calc(100% - 40px);
    }
  }
  .tab-text-view {
    padding: 35px 50px 80px;
  }
  .resort-item {
    padding: 15px 15px 35px;
  }
  .media-center-tabs {
    .tab-feature-img {
      width: 60px;
      height: 60px;
    }
    .tab-name {
      width: calc(100% - 60px);
    }
  }
  .press-releases-cards {
    .card-body {
      padding: 40px 30px 35px;
    }
  }
  .more-articles-sidebar {
    padding-left: 75px;
  }
  .collateral-card {
    padding: 20px;
  }
  .sustainability-cards {
    .right-feature-text-col {
      .right-feature-wrap {
        margin-top: 20px;
      }
    }
  }
  .our-values:not(.sustainability-cards) {
    .feature-left-image {
      height: 480px;
    }
  }
  .feature-left-image {
    height: 430px;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
  .value-card-text {
    p {
      height: 300px;
    }
  }
  .villa-full-details {
    .thumb-slides {
      .slick-list {
        height: 487px !important;
      }
    }
  }
  .other-villa-cards {
    .villa-image {
      height: 200px;
    }
  }
  .villas-list-page {
    .large-slides {
      .card-image {
        height: 430px;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  p {
    br {
      display: none;
    }
  }
  .dining-feature-text {
    padding-left: 60px;
  }
  .dining-features {
    .row {
      &:nth-child(even) {
        .dining-feature-text {
          padding-right: 60px;
        }
      }
    }
  }
  .other-restaurants {
    .slick-arrow-default {
      .slick-arrow {
        &.slick-prev {
          left: -20px;
        }
        &.slick-next {
          right: -20px;
        }
      }
    }
  }
  .akira-welcome {
    h2 {
      max-width: 820px;
    }
    .paragraph {
      max-width: 965px;
    }
  }
  .akira-page-wrap {
    background-size: 100%;
    .akira-text-card {
      padding-right: 75px;
    }
  }
  .akira-services-cards {
    .row {
      &:nth-child(even) {
        .akira-text-card {
          padding-left: 75px;
        }
      }
    }
  }
  .sustainability-page-wrap {
    .right-feature-wrap {
      padding-left: 75px;
      &.right-space {
        padding-right: 75px;
      }
    }
  }
  .experience-cards {
    .experience-card-text {
      padding-left: 60px;
    }
    .row {
      &:nth-child(even) {
        .experience-card-text {
          padding-right: 60px;
        }
      }
    }
  }
  .award-card {
    padding: 45px 40px;
  }
  .contact-card {
    padding-left: 15px;
    padding-right: 15px;
  }
  .award-year-title {
    font-size: 14.5px;
  }
  .gallery-slide-cards {
    .gallery-item {
      height: 420px;
    }
  }
  .guest-dropdown {
    .guests-input_options {
      padding: 0 15px;
    }
  }
  .atmosphere-book-now {
    .daterangepicker {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
      &.show-calendar {
        &.opensright {
          &::before,
          &::after {
            display: block !important;
          }
        }
      }
      &.opensright {
        &::before,
        &::after {
          display: none !important;
        }
      }
    }
    .ahr-datepicker-wrapper {
      position: relative;
    }
    .daterangepicker {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
    }
    .booking-form-input {
      .dropdown-container {
        .guest-dropdown {
          position: absolute !important;
          background-color: var(--bs-white);
          z-index: 1;
        }
      }
    }
    .discount-wrap {
      .dropdown-container {
        position: relative;
        .discount-dropdown {
          position: absolute !important;
          background-color: var(--bs-white);
          z-index: 1;
        }
      }
    }
  }
  @media (max-width: 1366px) {
    header {
      padding: 22px 20px;
      .header-wrap {
        &::before,
        &::after {
          width: calc(50% - 100px);
        }
      }
      .atmosphere-living-nav {
        .navbar-nav {
          .nav-item {
            .nav-link {
              span {
                padding: 8px 7px;
                font-size: 13px;
              }
            }
          }
          .nav-ul {
            width: calc(50% - 75px);
          }
          .booking-nav-item {
            .book-btn {
              font-size: 13px;
              padding-left: 15px !important;
              padding-right: 15px !important;
            }
          }
        }
      }
      .atmosphere-centered-logo {
        width: 150px;
        img {
          &.logo-fixed {
            top: -32px;
            max-width: 100%;
          }
        }
      }
    }
    .sub-card-para {
      padding: 30px;
    }
    .heading-space {
      letter-spacing: 5px;
    }
    .hotel-heading-wrap {
      padding-left: 90px;
      padding-right: 90px;
    }
    .gallery-card {
      .gallery-text-box {
        width: calc(100% - 55px);
        padding: 30px 25px 0;
        bottom: -90px;
      }
    }
    footer {
      .mein-footer {
        .useful-links-col {
          padding-left: 70px;
        }
      }
    }
    .gallery-slides {
      .slick-list {
        padding-bottom: 145px;
      }
    }
    .header-space {
      padding-top: 110px;
    }
    .atmosphere-book-now {
      padding: 40px 30px;
    }
    .slide-demo-box,
    .img-container {
      height: 500px !important;
    }
    .hotels-resorts {
      .right-slide-progress-bar {
        padding-right: 15px;
        .line-slide-status {
          width: calc(100% - 40px);
        }
        .slide-number {
          width: 40px;
        }
      }
    }
    .explore-kanifushi-plan {
      padding-left: 15px;
      padding-right: 15px;
      .rts___tabs___container {
        width: 300px;
        padding: 30px 15px;
        .tab-feature-img {
          width: 55px;
        }
        .tab-name {
          width: calc(100% - 55px);
          text-align: left;
        }
      }
      .rts___tabs___container ~ div {
        width: calc(100% - 300px);
      }
      .plan-tab-feature-img {
        width: calc(100% - 350px);
      }
      .plan-tab-faqs {
        width: 350px;
      }
      .plan-questions {
        padding: 35px 20px;
        .accordion-item {
          .accordion-button {
            padding: 22px 32px 22px 20px;
          }
        }
      }
      .hotel-heading-wrap {
        h2 {
          padding-left: 50px;
        }
      }
    }
    .explore-kanifushi-villas {
      padding-left: 15px;
      padding-right: 15px;
      .rts___tabs___container {
        width: 280px;
        padding: 45px 15px;
        .rts___btn {
          padding: 11px 0 11px 12px !important;
        }
      }
      .rts___tabs___container ~ div,
      .home-villa-scrollbar ~ div {
        width: calc(100% - 280px);
      }
      .villa-details {
        width: 340px;
        padding: 35px 15px;
      }
      .villas-slides {
        width: calc(100% - 340px);
      }
      .theme-btn {
        padding: 11.5px 20px;
      }
    }
    .explore-kanifushi-villas {
      .home-villa-scrollbar {
        max-width: 280px;
      }
    }
    .special-offers-slides {
      .slick-arrow.slick-next {
        right: -20px;
      }
      .slick-arrow.slick-prev {
        left: -20px;
      }
    }
    .our-plans-tabs {
      padding-left: 8px;
      padding-right: 8px;
    }
    .our-plans-tabs {
      .tab-head-text {
        padding: 0 25px;
      }
    }
    .page-not-found {
      padding: 230px 0 180px;
    }
    .gallery-slide-modal {
      .modal-dialog {
        max-width: 950px;
      }
    }
  }
  @media (max-width: 1199px) {
    body,
    html {
      overflow-x: hidden !important;
    }
    h1,
    .h1 {
      font-size: 42px;
    }
    h2,
    .h2 {
      font-size: 35px;
    }
    h3,
    .h3 {
      font-size: 29px;
    }
    h4,
    .h4 {
      font-size: 30px;
    }
    header {
      padding: 20px 20px;
      .header-wrap {
        &::before,
        &::after {
          content: none;
        }
      }
      .atmosphere-centered-logo {
        display: none;
      }
      .atmosphere-living-nav {
        .navbar-nav {
          padding: 0;
          .nav-item {
            width: 100%;
            margin: 0 0 3px;
            .nav-link {
              font-size: 12px;
              padding: 12px 0;
              &::before {
                width: 0;
                left: 0;
                right: auto;
                height: 2px;
              }
              span {
                padding: 0;
                background-color: transparent !important;
              }
              &:hover,
              &:focus {
                span {
                  color: #d39871 !important;
                }
                &::before {
                  width: 50px;
                }
              }
              &.active {
                &::before {
                  width: 50px;
                }
              }
            }
            &.booking-nav-item {
              display: none !important;
            }
          }
          .logo-item {
            width: 130px;
          }
          .navbar-collapse {
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 6px;
            padding: 15px 25px;
            backdrop-filter: blur(5px);
          }
          .navbar-collapse {
            transform: translateY(30px);
            transition: all 400ms ease-in-out 0s;
            -o-transition: all 400ms ease-in-out 0s;
            -ms-transition: all 400ms ease-in-out 0s;
            -moz-transition: all 400ms ease-in-out 0s;
            -webkit-transition: all 400ms ease-in-out 0s;
            &.show {
              transform: translateY(15px);
              margin-bottom: 15px;
            }
          }
          .nav-ul {
            width: 100%;
            &.end-nav-list,
            .start-nav-list {
              padding: 0;
            }
          }
        }
      }
      .device-logo {
        img {
          max-width: 175px;
        }
      }
      &.sticky {
        padding-top: 12px;
        padding-bottom: 12px;
        .atmosphere-living-nav {
          .navbar-nav {
            .logo-item {
              .logo-fixed {
                top: -26px;
              }
            }
          }
        }
        .mobile-book-now {
          .book-btn {
            background-color: var(--bs-white);
            border-color: var(--bs-white);
            color: #a97a5a !important;
            &:hover,
            &:focus {
              background-color: var(--dark-overlay);
              border-color: var(--dark-overlay);
              color: var(--bs-white) !important;
            }
          }
        }
      }
      .mobile-book-now {
        margin-top: 0;
        margin-right: 60px;
        .book-btn {
          padding: 7px 15px !important;
        }
      }
    }
    .logo-device-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .navbar-toggler {
      border: 1px var(--bs-white) solid;
      padding: 3px 6px;
      position: absolute;
      right: 0;
      top: -48px;
      span {
        background-image: url("../images/bar.svg");
        background-size: 20px;
      }
    }
    .atmosphere-book-now {
      padding: 30px 35px;
      width: calc(100% - 25px);
      .submit-form {
        margin-top: 30px;
      }
      .submit-col {
        // margin: auto;
        // margin-left: auto;
        // margin-right: auto;
       
      }
    //   .form-control {
    //     margin-bottom: 18px;
    //   }
    }
    .section-padding {
      padding: 75px 0;
    }
    .heading-space {
      letter-spacing: 2px;
    }
    .hotels-resorts-slides {
      padding: 0 30px;
    }
    .hotel-heading-wrap {
      padding: 0 17px;
    }
    .hotels-resorts-slides {
      .slick-dots {
        max-width: 590px;
        li {
          min-width: 160px;
          a {
            .thumb-wrap {
              padding: 25px 20px;
            }
            .thumb-img-box {
              margin: 15px 0 0;
            }
          }
        }
      }
      .slide-caption {
        h1 {
          font-size: 48px;
          letter-spacing: 5px;
        }
        span {
          font-size: 18px;
        }
      }
    }
    .sustainability {
      .sustain-card {
        .card-image {
          height: 50px;
          width: 50px;
          img {
            height: 100%;
          }
        }
      }
      .sustain-text-box {
        h5 {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .ask-form form {
      .check-terms {
        width: calc(100% - 187px);
      }
    }
    footer {
      .mein-footer {
        .footer-wrap {
          padding: 45px 30px 0;
        }
        .useful-links-col {
          padding-left: 0;
          margin: 35px 0 0;
        }
        .footer-logo {
          text-align: center;
          margin-bottom: 25px;
          img {
            max-width: 150px;
          }
        }
        .footer-col {
          .phone-footer {
            a {
              font-size: 18px;
            }
          }
        }
        .footer-links {
          a {
            &:not(:last-child) {
              span {
                padding-right: 24px;
              }
            }
          }
        }
      }
      .bottom-footer {
        padding: 25px 30px;
        margin: 25px 0 0;
        .footer-social {
          a {
            padding: 0 15px;
          }
        }
      }
    }
    .page-inner {
      padding: 40px 0;
    }
    .value-card-text {
      padding: 0 15px;
    }
    .value-card-text {
      .value-card-title {
        margin: 20px 0 70px;
        padding: 0 15px;
      }
      span {
        font-size: 21px;
      }
      p {
        padding: 20px 10px;
      }
    }
    .right-feature-text-col {
      max-width: 600px;
      .right-feature-wrap {
        padding: 40px 30px 25px;
      }
      .feature-text-para {
        margin: 25px 0 0;
        p {
          font-size: 18px;
        }
      }
    }
    .sub-brand-card {
      min-height: 456px;
    }
    .vertically-heading {
      position: relative !important;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      h1 {
        transform: none;
        letter-spacing: 0;
        margin: 0 0 30px;
      }
    }
    .book-now-modal {
      form {
        .form-control {
          font-size: 14px;
          padding: 15px 15px;
        }
      }
    }
    .commitment-feature {
      .row {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .sub-brands-tabs {
      padding-left: 20px;
      padding-right: 20px;
    }
    .tab-text-view {
      width: calc(100% - 20px);
      margin: -50px auto 0;
      padding: 25px 30px 50px;
    }
    .tab-text-view {
      .hotel-view-tab {
        padding: 15px;
      }
    }
    .resorts-view-all {
      flex-wrap: wrap;
      .resort-item {
        width: calc(50% - 14px);
        padding-bottom: 25px;
      }
    }
    .discover-left,
    .discover-right {
      .discover-feature-icon {
        width: 70px;
        height: 70px;
      }
      .discover-card-body {
        padding: 20px 30px;
        min-height: 270px;
      }
      .discover-card-image,
      .download-brochure {
        height: 270px;
      }
    }
    .brandfour {
      &::before {
        content: none;
      }
    }
    .resort-name-location {
      margin: 25px 0 0;
    }
    .rts___nav___btn___container.display___md___none {
      display: flex !important;
    }
    .contact-via-social {
      a {
        width: 70px;
        height: 70px;
      }
    }
    .contact-card {
      padding: 25px 15px;
    }
    .contact-card {
      p {
        letter-spacing: 1px;
        line-height: 1.5;
      }
      .content-text {
        min-height: 50px;
        a {
          letter-spacing: 1px;
        }
      }
    }
    .environmental-tourism {
      .environmental-tourism-card {
        padding: 50px;
      }
    }
    .sustainability-cards {
      .right-feature-text-col {
        .right-feature-wrap {
          padding: 40px 35px;
        }
        .feature-text-para {
          margin: 20px 0 0;
        }
      }
    }
    .offers-tabs {
      .offer-info-card {
        .hotel-heading-wrap {
          h2 {
            margin: 5px 0;
          }
          p {
            margin: 13px 0 18px;
          }
        }
      }
    }
    .all-offers-list {
      .tab-top-header {
        &:nth-child(odd) {
          padding: 75px 0;
        }
      }
    }
    .media-center-tabs {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .rts___tabs___container {
        margin: 0 auto 40px;
      }
      .rts___tab {
        padding: 6px;
        min-width: 260px;
      }
      .tab-feature-img {
        width: 50px;
        height: 50px;
      }
      .tab-name {
        width: calc(100% - 50px);
        font-size: 15px;
      }
    }
    .press-releases-cards {
      .card-body {
        padding: 30px 20px 25px;
        width: calc(100% - 52px);
      }
    }
    .more-articles-sidebar {
      padding-left: 15px;
    }
    .press-details {
      .press-img {
        margin: 0 0 30px;
      }
      .press-full-article {
        margin: 30px 0 0;
      }
      .press-title {
        width: calc(100% - 120px);
      }
    }
    .btn {
      &.theme-outline-btn {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    .thumb-articles {
      .thumb-press-card {
        margin-bottom: 35px;
      }
    }
    .sub-card {
      bottom: -230px;
    }
    .sub-card-para {
      p {
        min-height: 100px;
      }
    }
    .award-card {
      padding: 30px 20px;
      .award-by {
        span {
          min-height: 90px;
        }
      }
    }
    .collateral-card {
      padding: 15px;
      .collateral-text {
        width: calc(100% - 138px);
        h5 {
          font-size: 20px;
          margin-bottom: 3px;
        }
        p {
          font-size: 15px;
          line-height: normal;
        }
      }
      .collateral-docs-type {
        width: 90px;
        height: 90px;
      }
      .download-docs {
        a {
          width: 48px;
          height: 48px;
        }
      }
    }
    .header-space {
      padding-top: 105px;
    }
    .sustainability-cards {
      .feature-left-image {
        height: 350px;
      }
    }
    .green-globe-icon {
      img {
        max-width: 100px;
      }
    }
    .img-container {
      padding-left: 30px;
      padding-right: 30px;
    }
    div#moveableDiv {
      .thumb-wrap {
        width: 190px;
        margin-left: 624px;
        padding: 25px 15px;
      }
    }
    .hotels-resorts {
      .bottom-right-images {
        .thumb-img-box {
          margin-top: 15px;
        }
      }
      .slide-wrap {
        padding-top: 70px;
      }
      &.section-padding {
        padding-bottom: 25px;
      }
      .slide-wrap {
        width: calc(100% - 440px);
      }
      .slide-caption {
        h1 {
          font-size: 45px;
        }
      }
    }
    .bottom-right-images {
      max-width: 440px;
    }
    .slider-bottom-actions {
      margin-top: -120px;
    }
    .slide-demo-box,
    .img-container {
      height: 430px !important;
    }
    .our-values:not(.sustainability-cards) {
      .feature-left-image {
        height: 430px;
      }
    }
    // .atmosphere-book-now {
    //   .submit-col {
    //     margin-right: 0;
    //   }
    // }
    .explore-kanifushi-plan {
      .rts___tabs___container {
        width: 100%;
        border: 0;
        box-shadow: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        .tab-feature-img {
          width: 25px;
        }
        .tab-name {
          padding: 0 0 0 12px;
          width: calc(100% - 25px);
        }
        .rts___tabs {
          display: flex;
          width: calc(100% - 70px);
          .rts___btn {
            padding: 0 18px;
            margin: 0 10px 0 0;
            height: 52px;
            img {
              height: 20px;
            }
          }
        }
        .rts___nav___btn___container {
          display: flex !important;
          .rts___nav___btn {
            border: 0;
            &:hover {
              background-color: transparent;
              svg {
                stroke: var(--bs-black);
              }
            }
          }
        }
      }
      .rts___tabs___container ~ div {
        width: 100%;
      }
      .plan-tab-feature-img {
        padding-left: 0;
      }
    }
    .explore-kanifushi-villas,
    .explore-kanifushi-plan {
      .hotel-heading-wrap {
        h2 {
          padding-left: 15px;
        }
      }
    }
    .explore-kanifushi-villas {
      .rts___tabs___container,
      .home-villa-scrollbar {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border: 0;
        margin: 0 0 25px;
        height: auto !important;
        .rts___tabs {
          display: flex;
          flex-wrap: nowrap;
          width: calc(100% - 70px);
          .rts___btn {
            width: auto;
            border: 0 !important;
            margin: 0 10px 0 0 !important;
            padding: 12px 15px !important;
          }
          span {
            white-space: nowrap;
          }
        }
        .rts___nav___btn___container {
          display: flex !important;
        }
        .rts___btn {
          padding: 0 !important;
          border: 0;
          .rts___svg___icon {
            stroke: var(--bs-dark);
          }
          &:disabled {
            opacity: 0.5;
          }
          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
      .home-villa-scrollbar {
        max-width: 100%;
        > div {
          &:first-child {
            position: relative !important;
          }
        }
        .rts___tabs {
          .rts___btn {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
      .rts___tabs___container ~ div,
      .home-villa-scrollbar ~ div {
        width: 100%;
        padding-left: 0;
      }
    }
    footer {
      .footer-newsletter {
        h6 {
          letter-spacing: 2px;
        }
        form {
          margin-left: 30px;
        }
      }
    }
    .villas-list-page {
      .explore-villas {
        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }
    }
    .villa-full-details {
      .thumb-slides,
      .large-slides {
        width: 100%;
      }
      .thumb-slides {
        .slick-list {
          height: initial !important;
        }
      }
    }
    .villa-details-veiw {
      margin: 45px 0 0;
    }
    .vill-bottom-info {
      margin: 30px 0 0;
    }
    .signature {
      width: calc(50% - 12.5px);
    }
    .all-signatures {
      margin: 25px 0 0;
    }
    .all-amenities {
      .villa-amenity {
        width: calc(25% - 19px);
      }
    }
    .other-villa-cards {
      .hotel-heading-wrap {
        padding: 0;
      }
      .slick-arrow {
        &.slick-prev {
          left: -10px;
        }
        &.slick-next {
          right: -10px;
        }
      }
    }
    .slogan-two {
      font-size: 20px;
    }
    .villa-details-veiw {
      h4 {
        font-size: 24px;
      }
    }
    .villas-list-page {
      .large-slides {
        .card-image {
          height: 390px;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .dining-card {
      margin-bottom: 40px;
      .dining-card-body {
        h5 {
          font-size: 25px;
        }
      }
    }
    .dining-feature-text {
      padding-left: 30px;
    }
    .dining-features {
      .row {
        &:nth-child(even) {
          .dining-feature-text {
            padding-right: 30px;
          }
        }
        &:not(:last-child) {
          padding-bottom: 65px;
        }
      }
    }
    .other-restaurants {
      .slick-arrow-default {
        .slick-arrow {
          &.slick-prev {
            left: -10px;
          }
          &.slick-next {
            right: -10px;
          }
        }
      }
    }
    .restaurant-slide-card {
      .restaurant-text {
        h5 {
          font-size: 25px;
        }
      }
    }
    .akira-page-wrap {
      padding-bottom: 0;
      .akira-text-card {
        padding-right: 35px;
      }
      .akira-faqs {
        .accordion-item {
          .accordion-button {
            padding-left: 40px;
          }
        }
      }
    }
    .akira-hightlights {
      .right-feature-wrap {
        .highlight-points {
          li {
            padding: 17px 0 17px 0;
            span {
              padding-left: 35px;
            }
          }
        }
      }
    }
    .akira-highlight-img {
      img {
        max-width: 100%;
      }
    }
    .akira-services-cards {
      .row {
        margin-bottom: 60px;
        &:nth-child(even) {
          .akira-text-card {
            padding-left: 35px;
          }
        }
      }
    }
    .sustainability-page-wrap {
      .right-feature-wrap {
        padding-left: 45px;
        &.right-space {
          padding-right: 45px;
        }
        h4 {
          font-size: 30px;
        }
      }
      .green-globe-icon {
        top: 28px;
        right: -42px;
        img {
          max-width: 75px;
        }
      }
    }
    .experience-tab-data,
    .experience-cards {
      margin: 50px 0 0;
    }
    .experience-cards {
      .experience-card-text {
        padding-left: 15px;
      }
      .row {
        &:nth-child(even) {
          .experience-card-text {
            padding-right: 15px;
          }
        }
      }
      .experience-card-img {
        height: 370px;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .row {
        &:nth-child(even) {
          margin-top: 60px;
          margin-bottom: 60px;
        }
      }
      .experience-card-img {
        height: auto;
      }
    }
    .experience-tabs-view {
      .rts___btn {
        margin: 0 3px;
        .tab-name {
          padding: 0 15px;
        }
      }
    }
    .experience-tab-data,
    .experience-cards {
      margin: 40px 0 0;
    }
    .experience-cards {
      .row {
        &:nth-child(even) {
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
      .experience-card-text {
        padding: 0 !important;
        margin: 30px 0 0;
        h5 {
          font-size: 25px;
        }
      }
    }
    .gallery-slide-cards {
      .gallery-item {
        min-width: 75px;
        height: 340px;
      }
      .gallery-card-icon {
        width: 45px;
        height: 45px;
        padding: 10px;
      }
      .gallery-card-caption {
        left: 8px;
      }
    }
    .full-slide-view {
      .full-btn {
        padding: 8px 12px;
        font-size: 15px;
      }
    }
    .gallery-slide-modal {
      .modal-content {
        padding: 15px;
      }
    }
    .our-plans-tabs {
      .tab-head-text {
        top: 30px;
      }
      .feature-point-list {
        ul {
          min-height: 185px;
        }
      }
    }
    .plan-cover-image {
      height: 350px;
    }
    .slogan-text {
      height: 50px;
    }
    .villa-full-details {
      .thumb-slides {
        .slick-slide {
          padding: 0 7px;
        }
      }
    }
    .explore-kanifushi-plan {
      .rts___tabs___container {
        padding: 0 5px;
        .rts___tabs {
          .rts___btn {
            margin: 10px 0;
          }
        }
      }
    }
    .planscroll-custm {
      height: 340px !important;
    }
    .guest-dropdown {
      .guest-card {
        min-width: 100%;
      }
      .collapse {
        .guest-card {
          min-width: 100%;
        }
      }
    }
    .guest-dropdown {
      .guests-input_options {
        .guest-card {
          max-width: 160px;
          margin-left: auto;
        }
      }
    }
    .childs-dropdwon {
      position: absolute !important;
      top: calc(100% - 1px) !important;
    }

    .atmosphere-book-now {
      .submit-form {
        // margin-top: 12px;
      }
    }
  }

  @media (max-width: 1023px) {
    .atmosphere-book-now {
      .datepicker-open ~ .daterangepicker {
        flex-direction: column;
      }
    }
  }
  @media (max-width: 991px) {
    h1,
    .h1 {
      font-size: 35px;
    }
    h2,
    .h2 {
      font-size: 31px;
    }
    h3,
    .h3 {
      font-size: 24px;
    }
    h4,
    .h4 {
      font-size: 27px;
    }
    header {
      z-index: 99;
      .device-logo {
        img {
          max-width: 150px;
        }
      }
      .atmosphere-living-nav {
        .navbar-nav {
          .nav-item {
            .nav-link {
              padding: 12px 0 !important;
              span {
                padding: 0;
                background-color: transparent !important;
              }
              &::before {
                right: initial;
                margin: 0;
                left: 0;
                height: 2px;
              }
              &.active,
              &:hover,
              &:focus {
                &::before {
                  width: 50px;
                }
              }
              &:hover,
              &:focus {
                span {
                  color: #d39871 !important;
                }
              }
            }
          }
          .logo-right-item {
            margin-left: 0;
          }
          .logo-item {
            display: none;
          }
        }
      }
      &.sticky {
        padding-top: 12px;
        padding-bottom: 12px;
        .atmosphere-living-nav {
          .navbar-nav {
            .navbar-collapse {
              &.show {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
    .section-padding {
      padding: 60px 0;
    }
    .heading-icon {
      img {
        max-width: 45px;
        height: auto;
      }
    }
    .heading-space {
      letter-spacing: 0;
    }
    .hotels-resorts-slides {
      .slick-dots {
        margin-top: 20px;
      }
    }
    .sustainability {
      .sustainability-commitment {
        padding: 60px 15px 175px;
      }
      .sustain-card {
        margin-bottom: 24px;
      }
      .commitment-feature {
        margin-top: -125px;
      }
    }
    .ask-form {
      form {
        .bottom-actions {
          flex-wrap: wrap;
        }
        .check-terms {
          width: 100%;
          margin-bottom: 20px;
        }
        .form-control {
          padding: 13px 20px;
          font-size: 14px;
        }
      }
      .form-submit {
        width: 100%;
        text-align: right;
        .theme-btn {
          width: auto;
        }
      }
    }
    footer {
      .footer-newsletter {
        h6 {
          white-space: nowrap;
          letter-spacing: 0;
        }
        form {
          margin-left: 20px;
        }
      }
      .footer-logo {
        text-align: center;
      }
      .mein-footer {
        .useful-links-col {
          margin-top: 0;
        }
      }
      .no-border {
        border-right: 0 !important;
      }
      .bottom-footer {
        flex-wrap: wrap;
        .copyright {
          order: 3;
          width: 100%;
          text-align: center;
          margin: 20px 0 0;
          max-width: 100%;
        }
        .footer-social {
          order: 1;
        }
        .atmosphere-core-logo {
          order: 2;
          max-width: 200px;
        }
      }
    }
    .location {
      text-align: center;
      flex-wrap: wrap;
      span {
        justify-content: center;
      }
      .phone-footer {
        width: 100%;
        &:first-child {
          margin-bottom: 15px;
          margin-top: 13px;
        }
      }
    }
    .page-title {
      h2 {
        letter-spacing: 15px;
      }
    }
    .header-space {
      padding-top: 110px;
    }
    .value-card {
      margin-bottom: 30px;
    }
    .value-card-text {
      .value-card-title {
        margin: 60px 0 70px;
        padding: 0 35px;
      }
    }
    .value-card-text {
      p {
        padding: 20px 25px;
        height: auto;
      }
    }
    .right-feature-text-col {
      max-width: 510px;
      .right-feature-wrap {
        padding: 25px 20px 20px;
      }
      .top-head-text {
        span {
          font-size: 18px;
        }
      }
      .feature-text-para {
        margin: 15px 0 0;
        p {
          font-size: 16px;
        }
        &::after {
          margin: 25px auto 0;
        }
      }
    }
    .sub-brand-card {
      min-height: 336px;
      padding: 0 20px;
    }
    .atmosphere-book-now {
      margin-top: -40px;
    }
    .tab-text-view {
      .left-thumb-img,
      .right-hotel-text {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .right-hotel-text {
        margin: 20px 0 0;
        padding: 0;
        p {
          margin: 15px 0 0;
        }
        .visit-now {
          margin: 20px 0 0;
        }
      }
      .hotel-view-tab {
        padding: 25px;
      }
    }
    .sub-brands-tabs {
      .tab-feature-img {
        height: 30px;
        width: 30px;
      }
      .tab-name {
        width: calc(100% - 30px);
      }
    }
    .discover-left {
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      .discover-card {
        &:nth-child(1) {
          .discover-card-body {
            border-radius: 0 10px 0 0;
          }
        }
      }
    }
    .discover-right {
      border-radius: 0 0 10px 10px;
      .discover-card {
        flex-direction: row-reverse;
      }
      .discover-card-image,
      .discover-card-body {
        width: 50%;
      }
      .discover-card-body {
        &::before {
          transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          right: -13px;
          left: initial;
        }
      }
    }
    .resorts-view-all {
      gap: 20px;
      .resort-item {
        width: 100%;
      }
    }
    .contact-card {
      margin-bottom: 15px;
    }
    .dashed-heading {
      h3 {
        &::before {
          bottom: 13px;
        }
      }
    }
    .map-location {
      iframe {
        max-height: 250px;
      }
    }
    .dashed-heading {
      h3 {
        letter-spacing: 0;
      }
    }
    .contact-via-social {
      gap: 15px;
      a {
        width: 50px;
        height: 50px;
        font-size: 20px;
        img {
          width: 18px;
        }
      }
    }
    .sub-brands-tabs {
      padding: 0 20px;
    }
    .atmosphere-discover {
      padding-top: 60px !important;
    }
    .gallery-card {
      .gallery-text-box {
        bottom: -120px;
      }
    }
    .special-offers-slides {
      .slick-arrow {
        &.slick-prev {
          left: -650px;
        }
        &.slick-next {
          right: -650px;
        }
      }
    }
    .environmental-tourism {
      .environmental-tourism-card {
        padding: 35px;
      }
    }
    .sustainability-cards {
      .feature-left-image {
        min-height: 380px;
        img {
          object-fit: cover;
          height: 380px;
        }
      }
    }
    .btn.theme-btn-outline {
      padding: 15px 25px;
      font-size: 18px;
    }
    .offers-tabs {
      .offer-info-card {
        .hotel-heading-wrap {
          h2 {
            margin: 5px 0;
          }
          p {
            margin: 13px 0 18px;
          }
        }
      }
    }
    .all-offers-list {
      .tab-top-header {
        &:nth-child(odd) {
          padding: 60px 0;
        }
        &:last-child {
          padding-bottom: 60px;
        }
      }
    }
    .offers-tabs {
      .offer-info-card {
        max-width: 525px;
        .right-feature-wrap {
          padding: 20px 30px;
        }
      }
      .rts___tab {
        min-width: 175px;
      }
    }
    .offer-img {
      height: 344px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .dashed-heading {
      h2,
      h3,
      h4 {
        letter-spacing: 0;
      }
    }
    .media-center-tabs {
      padding-top: 45px;
      .rts___tabs___container {
        margin: 0 auto 30px;
      }
      .rts___tab {
        min-width: 220px;
      }
      .tab-feature-img {
        width: 40px;
        height: 40px;
        img {
          max-width: 20px;
        }
      }
      .tab-name {
        width: calc(100% - 40px);
        font-size: 14px;
      }
    }
    .press-releases-cards {
      .card-body {
        margin: -85px 26px 0;
      }
    }
    .more-articles-sidebar {
      padding-left: 0;
    }
    .press-details {
      .press-img {
        margin: 0 0 18px;
      }
      .press-title {
        width: 100%;
      }
      .press-full-article {
        margin: 15px 0 0;
      }
    }
    .btn {
      &.theme-outline-btn {
        padding: 10px 20px;
      }
    }
    .sub-card {
      bottom: -290px;
    }
    .sub-card-para {
      p {
        min-height: 155px;
      }
    }
    .sub-brand-subtitle {
      h4 {
        letter-spacing: 0;
      }
    }
    .media-center-tabs {
      form {
        .select-form {
          max-width: 300px;
        }
      }
    }
    .collateral-card {
      .collateral-text {
        width: calc(100% - 125px);
      }
      .collateral-docs-type {
        width: 80px;
        height: 80px;
      }
      .download-docs {
        a {
          width: 45px;
          height: 45px;
        }
      }
    }
    .header-space {
      padding-top: 65px;
    }
    .hotels-resorts {
      .slide-wrap {
        width: calc(100% - 340px);
      }
      .left-control-btns {
        width: 100px;
      }
      .right-slide-progress-bar {
        width: calc(100% - 100px);
        padding: 0 12px;
        .slide-number {
          span {
            font-size: 25px;
          }
        }
      }
      .slide-caption {
        h1 {
          font-size: 37px;
          letter-spacing: 0;
          padding-bottom: 8px !important;
          background-size: 28px;
        }
        span,
        small {
          font-size: 16px;
          letter-spacing: 2px;
        }
        a {
          font-size: 14px;
          margin-top: 15px !important;
          svg {
            font-size: 14px;
            margin-left: 6px !important;
          }
        }
      }
    }
    .bottom-right-images {
      max-width: 340px;
    }
    div#moveableDiv {
      .thumb-wrap {
        margin-left: 615px;
        width: 170px;
        span {
          font-size: 13px;
        }
      }
    }
    .slide-arrow-btns {
      width: 45px;
      height: 45px;
      img {
        max-width: 10px;
      }
    }
    .slide-demo-box,
    .img-container {
      height: 375px !important;
    }
    .our-values:not(.sustainability-cards) {
      .feature-left-image {
        height: 335px;
      }
    }
    .explore-kanifushi-villas,
    .explore-kanifushi-plan {
      .hotel-heading-wrap {
        h2 {
          padding-left: 0;
        }
      }
    }
    .hotel-heading-wrap {
      padding: 0;
    }
    .learnmore {
      text-align: left !important;
      margin: 18px 0;
    }
    .special-offers,
    .sustainability {
      &.section-padding {
        padding-bottom: 35px;
      }
    }
    .vill-bottom-info {
      ul {
        flex-wrap: wrap;
        align-items: flex-start !important;
      }
      li {
        width: auto;
        margin-bottom: 15px;
        align-items: flex-start;
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
    .explore-kanifushi-villas {
      .villa-details,
      .villas-slides {
        width: 100%;
      }
      .villas-slides {
        padding-left: 0;
        margin-top: 30px;
      }
    }
    .villas-list-page {
      .large-slides {
        .card-image {
          height: auto;
        }
      }
    }
    .dining-card {
      margin-bottom: 30px;
      .dining-card-body {
        h5 {
          font-size: 22px;
        }
      }
    }
    .dining-cat {
      padding: 10px 18px;
    }
    .letter-spacing-2 {
      letter-spacing: 1px;
    }
    .dining-left-side {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .dining-feature-text {
      padding-left: 0;
      margin-top: 30px;
    }
    .dining-features {
      .row {
        &:nth-child(even) {
          .dining-feature-text {
            padding-right: 0;
          }
        }
        &:not(:last-child) {
          padding-bottom: 50px;
        }
      }
    }
    .feature-point-list {
      li {
        padding: 15px 0 15px 35px;
      }
    }
    .restaurant-slide-card {
      .restaurant-text {
        h5 {
          font-size: 22px;
        }
      }
    }
    .akira-hightlights {
      .right-feature-text-col {
        position: relative !important;
        max-width: 100%;
        width: calc(100% - 50px);
        margin: -80px auto 0;
        .right-feature-wrap {
          max-width: 100%;
        }
      }
    }
    .akira-services-cards {
      .row {
        flex-direction: column-reverse;
        margin-bottom: 35px;
        &:nth-child(even) {
          flex-direction: column-reverse;
          .akira-text-card {
            padding-left: 0;
          }
        }
      }
    }
    .akira-page-wrap {
      .akira-text-card {
        padding-right: 0;
        margin-top: 25px;
      }
    }
    .sustainability-page-wrap {
      .right-feature-wrap {
        padding-left: 20px;
        &.right-space {
          padding-right: 20px;
        }
        h4 {
          font-size: 28px;
        }
      }
      .green-globe-icon {
        right: -20px;
        img {
          object-fit: initial;
          height: auto;
        }
      }
    }
    .explore-villas-tab {
      height: auto !important;
    }
    .gallery-slide-cards {
      flex-wrap: wrap;
      .gallery-item {
        margin: 7.5px 0;
        min-width: 100%;
        height: 75px;
        &.active {
          height: 453.5px;
        }
      }
    }
    .gallery-slide-modal {
      .modal-content {
        padding: 10px 10px 3px;
      }
      .slick-list {
        border-radius: 20px;
      }
      .slick-slide {
        height: 100%;
      }
      .slick-slide > div {
        height: 100%;
      }
      .gallery-slide {
        img {
          border-radius: 20px;
        }
      }
      .btn-close {
        width: 30px;
        height: 30px;
        background-size: 10px;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
    .navbar-toggler {
      top: -45px;
    }
    .plan-brochure {
      justify-content: flex-start !important;
      margin: 25px 0 0;
    }
    .plan-cover-image {
      height: 320px;
    }
    .slogan-text {
      height: auto;
    }
    .our-plans-tabs {
      .feature-point-list {
        ul {
          min-height: auto;
        }
        li {
          padding-left: 20px;
        }
      }
      .plan-card-text {
        padding: 20px 30px;
        height: auto;
        h5 {
          font-size: 23px;
        }
      }
    }
    .plan-head-text-view {
      .dining-left-side {
        flex-wrap: wrap;
      }
    }
    .media-center-tabs {
      padding-bottom: 45px;
    }
    .explore-kanifushi-villas {
      .rts___tabs___container,
      .home-villa-scrollbar {
        ~ div {
          height: auto;
        }
      }
      .home-villa-scrollbar {
        padding: 0;
        .rts___tabs___container {
          margin-bottom: 0;
        }
      }
    }
    .explore-villas-tab {
      .villas-slides {
        .slick-list {
          margin-left: 0;
          margin-right: 0;
        }
        .large-slides {
          height: auto;
        }
        .thumb-slides {
          .beach-slide-card {
            height: auto;
          }
        }
      }
    }
    .offers-tabs {
      padding-top: 45px;
    }
    .error-img {
      margin-top: 60px;
    }
    .page-not-found {
      padding: 230px 0 100px;
    }
    .glimpse-lightbox {
      width: 100%;
      &:first-child {
        img {
          border-radius: 10px 10px 0 0;
        }
      }
      &:nth-child(3),
      &:nth-child(4) {
        img {
          border-radius: 0;
        }
      }
      &:last-child {
        img {
          border-radius: 0 0 10px 10px;
        }
      }
    }
    .ril-outer {
      .ril__navButtons {
        background-size: 12px;
        padding: 25px 15px;
      }
    }
    .kanifushi-glimses {
      .glimpse-left {
        .glimpse-img {
          &:first-child {
            border-radius: 10px 10px 0 0;
          }
          &:last-child {
            border-radius: 0;
          }
        }
      }
      .glimpse-last {
        .glimpse-img {
          &:first-child {
            border-radius: 0;
          }
          &:last-child {
            border-radius: 0 0 10px 10px;
          }
        }
      }
    }
    .planscroll-custm {
      height: 270px !important;
    }
    .new-exprnc-tabs {
      .rts___tabs___container {
        gap: 5px;
      }
    }
  }
  @media (max-width: 767px) {
    h1,
    .h1 {
      font-size: 30px;
    }
    h2,
    .h2 {
      font-size: 28px;
    }
    h6,
    .h6 {
      font-size: 16px;
    }
    h4,
    .h4 {
      font-size: 23px;
    }
    p {
      font-size: 15px;
      line-height: 1.9;
    }
    .atmosphere-book-now {
      margin-top: 20px;
      width: calc(100% - 40px);
      padding: 20px;
      .discount-wrap {
        .dropdown-menu {
          min-width: 100%;
        }
      }
      .submit-col {
          margin: auto;
      }
    }
    .tab-cover {
      height: 220px;
      border-radius: 10px;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .atmosphere-discover {
      padding-top: 20px !important;
    }
    .section-padding {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .atmosphere-experience-await {
      .paragraph {
        p {
          font-size: 14.5px;
        }
      }
    }
    .btn.theme-btn {
      padding: 12px 25px;
    }
    .hotel-heading-wrap {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      .heading-space {
        width: 100%;
      }
      .view-all {
        margin: 10px auto 0;
      }
    }
    .heading-space {
      line-height: 1.4;
    }
    .dashed-heading {
      h2,
      h3 {
        &::before {
          content: none;
        }
      }
    }
    .gallery-card {
      .gallery-text-box {
        padding: 20px 18px 0;
        width: calc(100% - 40px);
        max-width: 100%;
        bottom: -60px;
      }
    }
    .gallery-slides {
      .slick-list {
        padding-bottom: 85px;
      }
    }
    .special-offers-slides {
      .gallery-text-box {
        h4 {
          font-size: 32px;
        }
      }
    }
    footer {
      .footer-newsletter {
        h6 {
          margin-bottom: 20px !important;
        }
        form {
          margin-left: 0;
          max-width: initial;
        }
      }
      .mein-footer {
        .footer-col,
        .footer-logo {
          border: 0;
          address {
            margin: auto;
          }
        }
        .location {
          width: 100%;
          text-align: center;
        }
      }
      .footer-logo {
        margin-bottom: 15px;
      }
    }
    .location {
      justify-content: center;
      .phone-footer {
        width: auto;
      }
    }
    .page-inner {
      padding: 30px 0;
    }
    .page-title {
      h2 {
        letter-spacing: 0;
      }
    }
    .dashed-heading {
      h2 {
        &::before {
          bottom: 16px;
        }
        span {
          padding-right: 15px;
        }
      }
    }
    .right-feature-text-col {
      max-width: initial;
      position: relative !important;
      width: calc(100% - 20px);
      margin: -50px auto 0;
    }
    .feature-card {
      &:hover,
      &:focus {
        .sub-card-title {
          height: auto;
          padding-bottom: 15px;
        }
      }
    }
    .sub-card-title {
      height: auto;
      padding: 35px 15px;
    }
    .atmosphere-hero-video {
      height: 500px;
      video {
        object-fit: cover;
        height: 100%;
      }
      .scroll-down {
        bottom: 40px;
      }
    }
    .book-now-modal {
      .modal-header {
        padding-bottom: 0;
      }
    }
    .modal-title {
      letter-spacing: 0;
      font-size: 24px;
    }
    .sub-card {
      bottom: -190px;
      .sub-card-title {
        height: 54px;
      }
    }
    .discover-left,
    .discover-right {
      .discover-feature-icon {
        width: 60px;
        height: 60px;
      }
      .discover-card-body {
        padding: 15px;
        p {
          margin: 0;
        }
      }
      .download-brochure {
        height: 220px;
      }
    }
    .special-offers-slides {
      .slick-arrow {
        &.slick-prev {
          left: 10px;
          right: initial;
        }
        &.slick-next {
          right: 10px;
          left: initial;
        }
      }
    }
    .brands-slide,
    .gallery-slides,
    .special-offers {
      .slick-slide {
        padding: 0 5px;
      }
      .slick-prev,
      .slick-next {
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.6) !important;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 8px !important;
        opacity: 1;
        &::before {
          content: none;
        }
      }
      .slick-prev {
        left: 0;
        background-image: url("../images/white-prev.svg") !important;
      }
      .slick-next {
        right: 0;
        background-image: url("../images/white-next.svg") !important;
      }
    }
    .environmental-tourism {
      padding-left: 10px;
      padding-right: 10px;
      .environmental-tourism-card {
        padding: 50px;
      }
    }
    .sustainability-cards {
      .feature-left-image {
        min-height: initial;
        img {
          height: auto;
        }
      }
      .right-feature-text-col {
        .right-feature-wrap {
          padding: 30px 25px;
        }
        .feature-text-para {
          margin: 15px 0 0;
        }
      }
    }
    .btn.theme-btn-outline {
      padding: 14px 25px;
      font-size: 16px;
      max-width: 280px;
      width: 100%;
    }
    .all-offers-list {
      .tab-top-header {
        &:nth-child(2n) {
          .col-md-9 {
            margin-left: 0;
          }
        }
        &:nth-child(odd) {
          padding: 40px 0;
        }
        &:last-child {
          padding-bottom: 40px;
        }
      }
    }
    .offers-tabs {
      .offer-info-card {
        margin-top: -50px !important;
        width: calc(100% - 20px);
      }
    }
    .press-releases-cards {
      .card-body {
        margin: -85px 15px 0;
        width: calc(100% - 30px);
      }
    }
    .thumb-articles {
      .thumb-press-card {
        margin-bottom: 20px;
      }
    }
    .sub-card-para {
      p {
        min-height: auto;
      }
    }
    .award-card {
      .award-by {
        margin: 20px 0 0;
        span {
          max-width: initial;
          min-height: initial;
        }
      }
    }
    .media-center-tabs {
      form {
        .select-form {
          max-width: 100%;
          margin: 5px 0;
        }
      }
    }
    .collateral-card {
      padding: 15px;
      .collateral-text {
        width: calc(100% - 115px);
        h5 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
      .collateral-docs-type {
        width: 70px;
        height: 70px;
      }
    }
    .page-inner {
      .breadcrumb {
        .breadcrumb-item + .breadcrumb-item {
          top: 1px;
          position: relative;
        }
      }
    }
    .sustainability-cards {
      .feature-left-image {
        height: auto;
      }
    }
    .green-globe-icon {
      top: initial;
      bottom: -25px;
      img {
        max-width: 70px;
      }
    }
    .our-values:not(.sustainability-cards) {
      .feature-left-image {
        height: auto;
      }
    }
    .mob-slide-image {
      height: 420px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .media-center-tabs {
      .tab-name {
        padding: 0 0;
      }
      .rts___tab {
        min-width: 195px;
      }
    }
    .explore-kanifushi-plan,
    .explore-kanifushi-villas {
      .btn.theme-btn-outline {
        margin: 15px auto 0;
        width: auto;
      }
    }
    .rts___nav___btn > svg {
      width: 20px !important;
    }
    .explore-kanifushi-plan {
      .plan-tab-feature-img {
        width: 100%;
        padding: 0;
        margin: 0 0 30px;
      }
      .plan-tab-faqs {
        width: 100%;
      }
    }
    .explore-kanifushi-villas {
      .villa-details,
      .villas-slides {
        width: 100%;
        height: auto;
      }
      .villas-slides {
        margin-top: 20px;
        padding: 0;
      }
    }
    .atmosphere-experience-await,
    .explore-kanifushi-plan {
      padding-left: 5px;
      padding-right: 5px;
    }
    .explore-kanifushi-villas,
    .sustainability,
    .kanifushi-glimses {
      padding-left: 5px;
      padding-right: 5px;
    }
    footer {
      .footer-newsletter {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .plan-villa {
      text-align: left;
      .theme-btn-outline {
        max-width: initial;
        width: auto;
      }
    }
    .slogan-two {
      font-size: 17px;
      margin: 5px 0 20px;
    }
    .all-signatures {
      gap: 15px;
    }
    .signature {
      width: 100%;
      height: auto;
    }
    .all-amenities {
      .villa-amenity {
        width: calc(33.333% - 17px);
      }
    }
    .other-villa-cards {
      .hotel-heading-wrap {
        .view-all {
          margin: 0;
        }
      }
      .other-villa-text {
        h5 {
          height: auto;
          margin: 15px 0 !important;
        }
        .theme-btn-outline {
          width: auto;
        }
      }
      .slick-slide {
        padding: 0 10px;
      }
    }
    .villa-full-details {
      .large-slides {
        .card-image {
          height: 275px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .thumb-slides {
        .card-image {
          height: 100px;
        }
      }
    }
    .vill-bottom-info {
      ul {
        width: 100%;
      }
      li {
        width: 100%;
        margin: 0 0 13px !important;
        span {
          width: calc(100% - 35px);
          &:first-child {
            width: 35px;
          }
        }
      }
    }
    .villas-list-page {
      .thumb-slides {
        .card-image {
          height: auto;
        }
      }
    }
    .dining-card {
      margin-bottom: 25px;
      .dining-card-body {
        padding: 25px 20px;
        .dining-timing {
          span {
            margin-bottom: 5px;
            font-size: 13px;
            padding: 4px 12px;
          }
        }
      }
    }
    .dining-cat {
      padding: 6px 12px;
      font-size: 14px;
    }
    .letter-spacing-2 {
      letter-spacing: 0;
    }
    .akira-page-wrap {
      padding-left: 5px;
      padding-right: 5px;
    }
    .akira-hightlights {
      .right-feature-text-col {
        margin-top: 20px;
        width: 100%;
      }
    }
    .sustainability-page-wrap {
      .right-feature-wrap {
        padding-left: 0;
        margin-top: 25px;
        &.right-space {
          padding-right: 0;
        }
        h4 {
          max-width: 100%;
          font-size: 22px;
        }
      }
      .green-globe-icon {
        right: 0;
        top: 0;
        img {
          object-fit: initial;
          height: auto;
        }
      }
    }
    .sustainability-cards {
      .row {
        &:nth-child(even) {
          flex-direction: column-reverse;
        }
      }
    }
    .experience-tabs-view {
      padding-left: 5px;
      padding-right: 5px;
      .experience-card-text {
        h5 {
          font-size: 22px;
        }
      }
      .rts___tabs___container {
        padding: 0;
      }
      .rts___btn {
        .tab-feature-img {
          width: 35px;
          height: 35px;
        }
        .tab-name {
          width: calc(100% - 35px);
          font-size: 14px;
          padding-left: 5px;
          padding-right: 10px;
        }
      }
    }
    .slick-arrow-default {
      .slick-arrow {
        width: 30px;
        height: 30px;
      }
    }
    .gallery-slide-modal {
      .modal-content {
        padding: 7px 7px 0;
      }
    }
    .our-plans-tabs {
      .download-brochure-btn {
        max-width: initial;
        width: auto;
      }
    }
    .dining-left-side {
      flex-wrap: wrap;
      margin-bottom: 5px !important;
    }
    .dining-name-time {
      margin-bottom: 15px;
    }
    .explore-kanifushi-villas {
      .rts___tabs___container {
        .rts___btn {
          border: 0;
          .rts___svg___icon {
            stroke: var(--bs-black);
          }
          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
    .explore-kanifushi-plan,
    .explore-kanifushi-villas {
      .plan-tab-feature-img {
        .tab-feature-image {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
    .vill-bottom-info {
      .book-now {
        width: 100%;
        margin-top: 15px;
      }
    }
    .vill-bottom-info {
      ul {
        width: 100%;
        li {
          white-space: initial;
        }
      }
    }
    .read-more-modal {
      padding: 15px 10px;
      .close-icon {
        width: 30px;
        height: 30px;
        right: -10px;
        top: -10px;
        font-size: 18px;
      }
    }
    .special-offers {
      .hotel-heading-wrap {
        .view-all {
          position: relative !important;
        }
      }
    }
    .gallery-card {
      .gallery-text-box {
        margin-top: -85px;
      }
    }
    .gallery-slide-cards {
      .gallery-item {
        &.active {
          height: 337.8px;
        }
      }
    }
    .award-by {
      min-height: initial;
    }
    .award-year-title {
      max-width: 185px;
    }
    .logo-wrapper {
      background-size: 135px;
    }
    .home-accordion-text {
      min-width: 100%;
      > div {
        height: auto !important;
        div {
          &:nth-child(1) {
            position: relative !important;
          }
        }
      }
    }
    .planscroll-custm {
      height: auto !important;
      > div {
        position: relative !important;
      }
    }
    .mob-arrow {
      &::before {
        top: 52px !important;
      }
    }
    .book-now-modal {
      .modal-content {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .experience-tabs-view {
      .rts___btn .gradnt-brdr {
        height: 55px;
      }
    }
    .ahr-guests-save-btn {
        padding: 12px 8px 8px;
    }
    .atmosphere-book-now {
      .datepicker-open ~ .daterangepicker {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }
  @media (max-width: 575px) {
    h1,
    .h1 {
      font-size: 27px;
    }
    .atmosphere-gallery {
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
    }
    footer {
      .bottom-footer {
        .footer-social,
        .atmosphere-core-logo {
          width: 100%;
          text-align: center;
          margin: 12px 0;
          max-width: 100%;
        }
        .atmosphere-core-logo {
          justify-content: center;
        }
      }
    }
    header {
      padding-left: 5px;
      padding-right: 5px;
      .device-logo {
        img {
          max-width: 130px;
        }
      }
      .mobile-book-now {
        margin-top: -5px;
      }
      &.sticky {
        .navbar-toggler {
          top: -41px;
        }
        .mobile-book-now {
          margin-top: 0;
        }
      }
    }
    .discover-left,
    .discover-right {
      .discover-card {
        flex-wrap: wrap;
        .discover-card-image,
        .discover-card-body {
          width: 100%;
          min-height: initial;
        }
        .discover-card-body {
          padding: 35px 20px;
          &::before {
            transform: rotate(90deg) !important;
            -o-transform: rotate(90deg) !important;
            -ms-transform: rotate(90deg) !important;
            -moz-transform: rotate(90deg) !important;
            -webkit-transform: rotate(90deg) !important;
            top: 0;
            right: 0 !important;
            left: 0 !important;
            margin: auto;
          }
        }
        &:nth-child(1) {
          .discover-card-body {
            border-radius: 0;
          }
        }
      }
    }
    .tab-text-view {
      padding: 15px 10px;
    }
    .special-offers-slides {
      .gallery-text-box {
        margin: -45px auto 0 !important;
      }
    }
    .environmental-tourism {
      .environmental-tourism-card {
        padding: 25px;
      }
    }
    .sub-card {
      p {
        min-height: 85px;
      }
    }
    .hotels-resorts {
      .hotel-resort-mobile-slide {
        h1 {
          font-size: 38px;
          margin-bottom: 10px;
        }
        span {
          font-size: 17px;
        }
        small {
          font-size: 15px;
        }
      }
    }
    .page-title h2 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .mob-slide-image {
      height: 350px;
    }
    .villa-full-details {
      padding-left: 10px;
      padding-right: 10px;
    }
    .all-amenities {
      .villa-amenity {
        width: calc(50% - 13px);
      }
    }
    .other-villa-cards {
      .slick-arrow {
        &.slick-prev {
          left: 5px;
        }
        &.slick-next {
          right: 5px;
        }
      }
    }
    .thumb-slide-default {
      .card-image {
        &::before {
          border-width: 3px;
        }
      }
      .card-image {
        border-radius: 10px;
      }
    }
    .explore-villas-tab {
      .villas-slides {
        .thumb-slide-default {
          .slick-slide {
            padding: 13px 6px 0;
          }
        }
      }
    }
    .explore-kanifushi-villas {
      .thumb-slide-default {
        .slick-slide {
          &.slick-current {
            .card-image {
              box-shadow: 0 0 5px 2.5px rgba(111, 82, 63, 0.3);
            }
          }
        }
      }
    }
    .explore-villas-tab {
      .villas-slides {
        .large-slides {
          .card-image {
            height: 220px;
          }
        }
      }
    }
    .gallery-slide-cards {
      .gallery-item {
        &.active {
          height: 320px;
        }
      }
    }
    .experience-tabs-view {
      .rts___btn {
        min-width: 250px;
      }
    }
  }
  @media (max-width: 420px) {
    .sub-card {
      bottom: -215px;
      p {
        min-height: 105px;
      }
    }
    .hotels-resorts {
      .hotel-resort-mobile-slide {
        h1 {
          font-size: 32px;
        }
        span {
          font-size: 16px;
        }
      }
    }
    .page-not-found {
      .error-text {
        h2 {
          font-size: 40px;
        }
      }
    }
  }
  @media (max-width: 380px) {
    .all-amenities {
      .villa-amenity {
        width: 100%;
      }
    }
  }
  @media (max-width: 300px) {
    header {
      .device-logo {
        img {
          max-width: 115px;
        }
      }
      .mobile-book-now {
        margin-right: 41px;
        .book-btn {
          padding: 7px 6px !important;
          font-size: 11px;
        }
      }
    }
    .navbar-toggler {
      padding: 3px 4px;
      top: -34px;
      span {
        background-size: 15px;
        width: 25px;
        height: 25px;
      }
    }
    footer {
      .footer-newsletter {
        h6 {
          white-space: initial;
          text-align: center;
        }
      }
    }
    .value-card-text {
      p {
        padding: 15px 12px;
      }
      .value-card-title {
        margin: 15px 0;
      }
    }
    .value-card-img {
      height: 470px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .sustainability-cards {
      .right-feature-text-col {
        .top-head-text {
          h4 {
            letter-spacing: 0;
            font-size: 24px;
          }
        }
      }
    }
    .page-not-found {
      padding: 110px 0;
    }
  }
  @media (max-width: 991px) {
    .getting-ready {
      padding: 0 20px;
    }
    .project-logo img {
      max-width: 200px;
    }
    .toc-getting-ready .project-logo img {
      max-width: 120px;
    }
    .getting-ready-inner {
      max-width: 550px;
      margin: auto;
      padding: 35px 50px 85px;
    }
    .getting-ready-content {
      margin-top: 80px;
    }
    .getting-ready-content h1 {
      margin-bottom: 0;
      font-size: 35px;
    }
    .getting-ready-content p {
      font-size: 16px;
    }
    .getting-ready-content .book-now-btn {
      margin-top: 20px;
    }
    .footer-info {
      padding: 0 50px;
    }
  }
  @media (max-width: 767px) {
    .getting-ready-inner {
      padding-bottom: 150px;
    }
    .footer-info {
      height: auto;
      display: inline-block;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .footer-info a {
      display: block;
      margin: 10px 0;
    }
    .footer-info a:first-child {
      margin-right: 20px;
    }
    .guestmodal {
        &.show {
          .modal-dialog {
            transform: translateY(0px) !important;
          }
        }
        &.fade {
          transition: none;
          .modal-dialog {
            transform: translateY(300px);
            transition: transform 700ms;
          }
        }
      }
  }
  @media (max-width: 575px) {
    .getting-ready {
      padding: 0 15px;
    }
    .getting-ready-inner {
      max-width: 550px;
      margin: auto;
      padding: 25px 20px;
    }
    .getting-ready-content {
      margin-top: 50px;
    }
    .getting-ready-content h1 {
      font-size: 28px;
    }
    .getting-ready-content p {
      font-size: 16px;
    }
    .footer-info {
      padding: 12px 20px;
    }
    .atmosphere-book-now {
      .daterangepicker {
        position: absolute !important;
        top: auto !important;
        left: auto !important;
        border: 0 !important;
        margin-top: 0 !important;
        &.show-calendar {
          border: 1px solid #ddd !important;
          margin-top: 7px !important;
          &.opensright {
            &::before,
            &::after {
              display: flex !important;
            }
          }
        }
        &.opensright {
          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }
  }
  @media (max-width: 420px) {
    .btn.theme-btn-outline {
      padding: 14px 10px;
    }
  }
}
